import React from 'react';
import loadable from '@loadable/component';

import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';
import PreviewResolverPage from '../containers/PreviewResolverPage/PreviewResolverPage';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage'));
const CMSPage = loadable(() => import(/* webpackChunkName: "CMSPage" */ '../containers/CMSPage/CMSPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage'));
const ListingPageCoverPhoto = loadable(() => import(/* webpackChunkName: "ListingPageCoverPhoto" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCoverPhoto'));
const ListingPageCarousel = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCarousel'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPageWithMap = loadable(() => import(/* webpackChunkName: "SearchPageWithMap" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithMap'));
const SearchPageWithGrid = loadable(() => import(/* webpackChunkName: "SearchPageWithGrid" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithGrid'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage'));
const SellerPage = loadable(() => import(/* webpackChunkName: "SellerPage" */ '../containers/SellerDashboard/SellerPage'));
const InfluencerPage = loadable(() => import(/* webpackChunkName: "InfluencerPage" */ '../containers/InfluencerDashboard/InfluencerPage'));
const SalesAccountPage = loadable(() => import(/* webpackChunkName: "SalesAccountPage" */ '../containers/SalesAccountPage/SalesAcountPage'));

const DeleteAccountPage = loadable(() =>import(/* webpackChunkName: "DeleteAccountPage" */ '../containers/DeleteAccountPage/DeleteAccountPage'));
const EarningsPage = loadable(() => import(/* webpackChunkName: "EarningsPage" */ '../containers/EarningsPage/EarningsPage'));
const ProjectsPage = loadable(() => import(/* webpackChunkName: "ProjectsPage" */ '../containers/ProjectsPage/ProjectsPage'));
const RefundsPage = loadable(() => import(/* webpackChunkName: "RefundsPage" */ '../containers/RefundsPage/RefundsPage'));

const ProductPage = loadable(() => import(/* webpackChunkName: "SalesAccountPage" */ '../containers/ProductPage/ProductPage'));
const CartsPage = loadable(() => import(/* webpackChunkName: "SalesAccountPage" */ '../containers/CartsPage/CartsPage'));
const PurchasesPage = loadable(() => import(/* webpackChunkName: "SalesAccountPage" */ '../containers/PurchasesPage/PurchasesPage'));
const WishlistPage = loadable(() => import(/* webpackChunkName: "SalesAccountPage" */ '../containers/WishlistPage/WishlistPage'));
const CanceledPage = loadable(() => import(/* webpackChunkName: "SalesAccountPage" */ '../containers/CanceledPage/CanceledPage'));
const GigsPage = loadable(() => import(/* webpackChunkName: "GigsPage" */ '../containers/GigsPage/GigsPage'));
const PendingProposalsPage = loadable(() => import(/* webpackChunkName: "PendingProposalsPage" */ '../containers/PendingProposalsPage/PendingProposalsPage'));
const TransactionHistoryPage = loadable(() => import(/* webpackChunkName: "TransactionHistoryPage" */ '../containers/TransactionHistoryPage/TransactionHistoryPage'));
//const SubscriptionPage = loadable(() => import(/* webpackChunkName: "SubscriptionPage" */ '../containers/SubscriptionPage/SubscriptionPage'));
const GigsTrackingPage = loadable(() => import(/* webpackChunkName: "GigsTrackingPage" */ '../containers/GigsTrackingPage/GigsTrackingPage'));
const IncomePage = loadable(() => import(/* webpackChunkName: "SubscriptionPage" */ '../containers/IncomePage/IncomePage'));
const PaypalAppPage = loadable(() => import(/* webpackChunkName: "PaypalAppPage" */ '../containers/PaypalAppPage/PaypalAppPage'));
const PaymentSettingPage = loadable(() => import(/* webpackChunkName: "PaymentSettingPage" */ '../containers/PaymentSettingPage/PaymentSettingPage'));
const OrderDetailsPage = loadable(() => import(/* webpackChunkName: "OrderDetailsPage" */ '../containers/TransactionPage/TransactionPage'));



// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'DeleteAccountPage',
  'SalesAccountPage',
  'EarningsPage',
  'ProjectsPage',
  'RefundsPage',
  'ProductPage',
  'CartsPage',
  'PurchasesPage',
  'WishlistPage',
  'CanceledPage',
  'GigsPage',
  'PendingProposalsPage',
  'TransactionHistoryPage',
  'GigsTrackingPage',
  'IncomePage',
  'PaypalApp',
  'PaymentSettingPage',
  'OrderDetailsPage'
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = (layoutConfig) => {
  const SearchPage = layoutConfig.searchPage?.variantType === 'map' 
    ? SearchPageWithMap 
    : SearchPageWithGrid;
  const ListingPage = layoutConfig.listingPage?.variantType === 'carousel' 
    ? ListingPageCarousel 
    : ListingPageCoverPhoto;
  
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: (pageDataLoadingAPI.LandingPage.loadData),
    },
    {
      path: '/p/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/sellers',
      name: 'SearchPageSellers',
      component: SearchPage,
      params:{pub_role:"Sellers"},
      loadData: (params, ...rest) =>
      pageDataLoadingAPI.SearchPage.loadData({},{"?pub_role":"Sellers"},...rest),
   
    },

    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    {
      path: '/account/delete-profile',
      name: 'DeleteAccountPage',
      auth: true,
      authPage: 'LoginPage',
      component: DeleteAccountPage,
    },

    {
      path: '/account/sales',
      name: 'SalesAccountPage',
      auth: true,
      authPage: 'LoginPage',
      component: SalesAccountPage,
    },
    

    {
      path: '/account/seo',
      name: 'EarningsPage',
      auth: true,
      authPage: 'LoginPage',
      component: EarningsPage,
    },

    {
      path: '/account/projects',
      name: 'ProjectsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProjectsPage,
    },

    {
      path: '/account/refunds',
      name: 'RefundsPage',
      auth: true,
      authPage: 'LoginPage',
      component: RefundsPage,
    },

  
    {
      path: '/account/products',
      name: 'ProductPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProductPage,
    },

    {
      path: '/account/carts',
      name: 'CartsPage',
      auth: true,
      authPage: 'LoginPage',
      component: CartsPage,
    },

    {
      path: '/account/purchases',
      name: 'PurchasesPage',
      auth: true,
      authPage: 'LoginPage',
      component: PurchasesPage,
    },

    {
      path: '/account/wishlist',
      name: 'WishlistPage',
      auth: true,
      authPage: 'LoginPage',
      component: WishlistPage,
    },

    {
      path: '/account/canceled',
      name: 'CanceledPage',
      auth: true,
      authPage: 'LoginPage',
      component: CanceledPage,
    },
    {
      path: '/account/gigs',
      name: 'GigsPage',
      auth: true,
      authPage: 'LoginPage',
      component: GigsPage,
    },
    {
      path: '/account/pending-proposals',
      name: 'PendingProposalsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PendingProposalsPage,
    },
    {
      path: '/account/transaction-history',
      name: 'TransactionHistoryPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionHistoryPage,
    },

    {
      path: '/account/gigs-tracking',
      name: 'GigsTrackingPage',
      auth: true,
      authPage: 'LoginPage',
      component: GigsTrackingPage,
    },

    {
      path: '/account/income',
      name: 'IncomePage',
      auth: true,
      authPage: 'LoginPage',
      component: IncomePage,
    },


    {
      path: '/seller',
      name: 'SellerPage',
      auth: false,
      component: SellerPage,
    },
    {
      path: '/influencer',
      name: 'InfluencerPage',
      auth: false,
      component: InfluencerPage,
    },

    {
      path: '/payment',
      name: 'PaypalAppPage',
      auth: false,
      component: PaypalAppPage,
      
    },
    {
      path: '/payment-setting',
      name: 'PaymentSettingPage',
      auth: false,
      component: PaymentSettingPage,
      
    },
    

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }, ...rest),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ id: props.params?.id }} />,
    },
    {
      path: '/sale/:id',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ id: props.params?.id }} />,
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
      loadData: pageDataLoadingAPI.TermsOfServicePage.loadData,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
      loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /preview endpoint
    {
      path: '/preview',
      name: 'PreviewResolverPage',
      component: PreviewResolverPage ,
    },
  ];
};

export default routeConfiguration;
